import React from "react";

import { StaticImage } from "gatsby-plugin-image";
import classNames from "../../utils/utils";
import {
  PuzzleIcon,
  CodeIcon,
  QrcodeIcon,
  CubeTransparentIcon,
} from "@heroicons/react/outline";

const services = [
  {
    name: "Transformation numérique",
    description:
      "Nous numérisons vos processus afin de multiplier vos revenus tous en réduisons vos coûts.",
    icon: QrcodeIcon,
    color:
      "text-sky-600 hover:border-sky-600 bg-gradient-to-bl from-green-100 to-blue-200",
  },
  {
    name: "Intégration ERP",
    description:
      "Nous réalisons l’étude et la mise en œuvre d'Odoo, un ERP open source et puissant, qui vous permet de gérer toute votre entreprise à partir d'une seule application.",
    icon: PuzzleIcon,
    color:
      "text-red-600 bg-gradient-to-bl from-orange-100 to-red-300 hover:border-red-600",
  },
  // {
  //   name: "Architecture de solution",
  //   description:
  //     "Nous developpons une architecture logicielle les meilleures performances,  Atteignez une évolutivité, des performances et une optimisation des coûts mesurables grâce au développement d'une architecture logicielle résiliente.",
  //   icon: ChipIcon,
  //   color:
  //     "text-indigo-600 bg-gradient-to-bl from-pink-100 to-indigo-300 hover:border-indigo-600",
  // },
  {
    name: "Développement web",
    description:
      "Nous développons des applications et site Web de qualité supérieure avec des technologies de pointe pour des performances optimales.",
    icon: CodeIcon,
    color:
      "text-green-600 bg-gradient-to-bl from-yellow-50 to-green-200 hover:border-green-600",
  },
  // {
  //   name: "Branding numérique",
  //   description:
  //     "Nous vous façonnons une image de marque forte afin d'influcer la façon dont les consommateurs perçoivent votre entreprise et créez de nouvelles affaires",
  //   icon: StarIcon,
  //   color:
  //     "text-yellow-600 bg-gradient-to-bl from-pink-100 to-yellow-200 hover:border-purple-600",
  // },
  {
    name: "Solution Open source",
    description:
      "Nous étudions les besoins software de votre entreprise, et vous proposons des logiciels puissant, fiable et économique adapté qui seront booster votre productivité.",
    icon: CubeTransparentIcon,
    color:
      "text-pink-600 bg-gradient-to-bl from-lime-50 to-pink-200 hover:border-pink-600",
  },
  // {
  //   name: "Vitesse",
  //   description:
  //     "Nous integrons vos besoin avec Odoo ERP, To understand when we should use code splitting, it’s helpful to introduce the term Time-to-Interactive (TTI). It’s a value that specifies the time required to load all assets essential for the website to initialise and become interactive for the user. Unfortunately, when it comes to SPAs, TTI is often too long for the user to patiently keep waiting. Various surveys by Akamai and Gomez.com conclude that almost half of all users expect the page to load in under 2 seconds. Extending it by another second compels the user to abandon it.",
  //   icon: LightningBoltIcon,
  //   color: "text-pink-600 bg-pink-100",
  // },
];

const ServicesSection = () => (
  <section id="services" className="h-full bg-white snap-center mx-auto">
    <div className="flex flex-col lg:flex-row">
      <div className="m-4 sm:m-10 lg:w-5/6 xl:2/3">
        <h2
          className="m-0 text-4xl font-extrabold leading-tight tracking-tight text-left text-black sm:text-4xl md:text-5xl"
          data-sal="slide-right"
          data-sal-delay="300"
          data-sal-easing="ease"
        >
          Services et prestations
        </h2>
        <p
          className="mt-2 mb-12 text-xl text-left border-0 border-gray-200 sm:text-2xl"
          data-sal="slide-right"
          data-sal-delay="300"
          data-sal-easing="ease"
        >
          Faites appel à nous pour accélérer votre transformation numérique.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 lg:gap-x-6 gap-y-6">
          {services.map((service, i) => (
            <div
              data-sal="slide-left"
              data-sal-delay={400 + i * 100}
              data-sal-easing="ease"
              data-sal-repeat
              className={classNames(
                service.color,
                "group relative rounded-3xl p-4 shadow-md hover:from-white hover:to-white border-4 hover:shadow-none border-white hover:rounded-none"
              )}
            >
              <div className="relative">
                <div
                  className={classNames(
                    "bg-white flex items-center justify-center w-12 h-12 mb-4 rounded-full transform transition-all group-hover:scale-150"
                  )}
                >
                  <service.icon className="w-8 h-8" />
                </div>
                <div className="flex text-black">
                  <h3 className="mb-2 text-2xl font-normal uppercase leading-tight text-black lg:text-3xl">
                    {service.name}
                  </h3>
                </div>
                <p className="text-sm text-gray-700 lg:text-base">
                  {service.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="h-40 lg:w-1/2 lg:h-auto">
        <StaticImage
          layout="fullWidth"
          src="../../images/amy-shamblen-310bcopEdXU-unsplash.jpg"
          className="object-cover w-full h-full"
        />
      </div>
    </div>
  </section>
);

/* <section className="container flex flex-col items-center h-full mx-auto text-center bg-green-100">
          <div className="text-4xl font-extrabold h-1/3">
            Nous vous offrons notre expertise et prestation
          </div>
          <div>
            <div className="text-xl">Rapport personalisé</div>
            <div className="text-xl">Developement personalisé</div>
            <div className="text-xl">Support Funcionel</div>
            <div className="text-xl">Support Technique</div>
            <div className="text-xl">Systeme integration</div>
            <div className="text-xl">Application odoo personalisé</div>
            <div className="text-xl">Support l'algerie</div>
          </div>
        </section> */
export default ServicesSection;
