import React from "react";
import loadable from "@loadable/component";
import LogoIcon from "../../svg/logo.svg";
import LogoBrand from "../../svg/brand.svg";
import { MailIcon, PhoneIcon, ClockIcon } from "@heroicons/react/outline";
const Loading = () => (
  <div className="grid w-full h-full mx-auto place-items-center h-72">
    <ClockIcon className="text-blue-600 h-14 w-14 animate-spin" />
  </div>
);
const Contact = loadable(
  () => import(/* webpackPrefetch: true */ "./Contact"),
  {
    fallback: <Loading />,
  }
);
const Footer = () => (
  <footer className="bg-blue-50">
    <div className="container px-3 mx-auto text-gray-800 ">
      <div className="">
        <div className="flex flex-col-reverse mx-auto lg:flex-row max-w-7xl">
          <div className="px-4 py-8 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
            <div className="max-w-lg mx-auto">
              <p className="text-lg leading-6 text-gray-700">
                <div className="flex items-center">
                  <div className="w-12 mr-3">
                    <LogoIcon />
                  </div>
                  <div className="w-64 mr-3">
                    <LogoBrand />
                  </div>
                </div>
                {/* Nous somme votre partenaire solution informatique, faite nous
                confiance avec vos projets */}
              </p>
              <dl className="mt-8 text-base text-gray-500">
                {/* <div>
                  <dt className="sr-only">Postal address</dt>
                  <dd>
                    <p>742 Evergreen Terrace</p>
                    <p>Springfield, OR 12345</p>
                  </dd>
                </div> */}
                <div className="mt-6 text-blue-700 hover:underline">
                  <dt className="sr-only">Phone number</dt>
                  <dd className="flex">
                    <PhoneIcon
                      className="flex-shrink-0 w-6 h-6"
                      aria-hidden="true"
                    />
                    <span className="ml-3">
                      <a href="tel:+213-55-03-85-56">+213 (0) 55 93 85 56</a>
                    </span>
                  </dd>
                </div>
                <div className="mt-3 text-blue-700 hover:underline">
                  <dt className="sr-only">Email</dt>
                  <dd className="flex">
                    <MailIcon
                      className="flex-shrink-0 w-6 h-6"
                      aria-hidden="true"
                    />
                    <span className="ml-3">
                      <a href="mailto:contact@codeconik.com">
                        contact@codeconik.com
                      </a>
                    </span>
                  </dd>
                </div>
              </dl>
              {/* <p className="mt-6 text-base text-gray-500">
              Looking for careers?{" "}
              <a href="#" className="font-medium text-gray-700 underline">
                View all job openings
              </a>
              .
            </p> */}
            </div>
          </div>
          <Contact />
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
