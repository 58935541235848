import React from "react";

import AnchorLink from "react-anchor-link-smooth-scroll";
import LogoIcon from "../../svg/logo.svg";
import LogoBrand from "../../svg/brand.svg";
import SymbIcon from "../../svg/symb.svg";
import OpenBracket from "../../svg/open-bracket.svg";
import ClosedBracket from "../../svg/closed-bracket.svg";
import { FiSend } from "react-icons/fi";
import { StaticImage } from "gatsby-plugin-image";
const rotating_text = [
  "Le code",
  "Le design",
  "Le web",
  "Le logiciel Libre",
  "Les ERP",
  "Nos clients",
  "Les glaces",
];
const Top = () => (
  <section id="top" className="relative w-full h-screen mx-auto snap-start">
    <StaticImage
      src="../../images/jexo-yVxUC9I9Cik-unsplash.jpg"
      className="absolute inset-0 object-cover w-full h-full opacity-30"
      alt=""
    />
    <div className="absolute inset-0 bg-gradient-to-bl from-sky-600 to-green-600 opacity-60"></div>
    <div
      data-sal="slide-up"
      data-sal-delay="300"
      data-sal-easing="ease"
      className="absolute inset-0 w-full p-1 pr-3 text-base font-bold text-right text-white lg:text-xl"
    >
      <a href="tel:+213-55-03-85-56">
        <SymbIcon className="inline h-10 mx-1 text-white fill-current" />
        +213 (0) 55 93 85 56
      </a>
    </div>
    <div className="absolute inset-0 flex flex-col-reverse items-center h-full mx-auto mt-6 md:container md:flex-row">
      <div className="m-auto ml-6 md:w-2/3 h-1/2 md:h-auto">
        <div>
          <div className="m-auto mt-20 mb-5 text-5xl font-extrabold text-transparent bg-clip-text md:my-0 sm:text-6xl bg-gradient-to-r from-pink-200 to-lime-300 lg:text-7xl xl:text-8xl lg:mb-6">
            Passionné par
          </div>
          <div className="m-auto text-white transform rounded-lg rotating_container lg:scale-110 lg:translate-x-8 xl:scale-125 xl:translate-x-20 2xl:translate-x-24">
            <ul className="text-4xl font-light uppercase rotating_list sm:text-5xl ">
              {rotating_text.map((text) => (
                <li className="transform translate-y-1 rotating_list_item xl:pl-3">
                  <OpenBracket className="inline h-12 mx-0 text-white transform -translate-y-1 fill-current" />
                  {text}
                  <ClosedBracket className="inline h-12 mx-0 text-white transform -translate-y-1 fill-current" />
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div>
          <div className="h-4 sm:h-12"></div>
          <AnchorLink
            href="#contact"
            className="px-8 py-3 text-2xl font-bold text-white bg-pink-400 bg-opacity-75 lg:py-4 lg:px-12 xl:py-5 xl:px-16 rounded-xl hover:bg-transparent hover:border-4"
          >
            Contactez-nous
            <FiSend className="inline w-8 h-8 ml-5 " />
          </AnchorLink>
        </div>
      </div>
      <div className="flex flex-col items-center pt-10 mx-8 md:m-auto md:pt-0 h-1/2 md:h-auto md:w-1/3 lg:w-1/2 md:mr-6">
        <LogoIcon className="text-white fill-current w-60 sm:w-80 lg:w-96 md:w-auto" />
        <LogoBrand className="text-white fill-current" />
      </div>
    </div>
  </section>
);

export default Top;
