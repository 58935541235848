import React from "react";
import classNames from "../../utils/utils";
import {
  PuzzleIcon,
  StarIcon,
  CheckIcon,
  UserAddIcon,
  CashIcon,
  DocumentTextIcon,
  BriefcaseIcon,
  GlobeIcon,
  ShoppingCartIcon,
  DocumentDuplicateIcon,
  UsersIcon,
} from "@heroicons/react/outline";
import { AiOutlineShop } from "react-icons/ai";
import { MdOutlinePrecisionManufacturing } from "react-icons/md";

const stats = [
  {
    name: "D'utilisateurs",
    number: "7 Millions",
    icon: UsersIcon,
    color: "bg-purple-300",
  },
  {
    name: "Extenstions",
    number: "+ 30000",
    icon: PuzzleIcon,
    color: "bg-red-300",
  },
  // {
  //   name: "Pays",
  //   number: "+ 160",
  //   icon: GlobeIcon,
  //   color: "bg-green-300",
  // },
  {
    name: "Partenaires",
    number: "+ 2500",
    icon: GlobeIcon,
    color: "bg-green-300",
  },
];
const features = [
  {
    name: "Plateforme tout-en-un",
    description:
      "Odoo dispose d'un large éventail d'applications pour couvrir tous vos besoins : ventes, CRM, facturation, et gestion, gestion de projet, production, marketing, e-commerce et bien plus encore.",
  },
  {
    name: "Grande modularité",
    description:
      "Personnalisé votre logiciel de gestion idéal en n'intégrant que les applications dont vous avez besoin.",
  },
  {
    name: "Facilement extensible",
    description:
      "Avec un store de +30000 add-ons personnaliser votre ERP n'a jamais été plus facile.",
  },
  {
    name: "Centralisation",
    description:
      "Grâce à Odoo, tout le système d'information de votre entreprise est centralisé.",
  },
  {
    name: "Efficacité",
    description:
      "Toutes les tâches chronophages sont automatisées. Plus de pertes de temps sur la gestion, l’administratif, les relations commerciales. ",
  },
  {
    name: "Logiciel de gestion le plus installé au monde",
    description:
      "Odoo est le logiciel de gestion le plus installé au monde. Il est utilisé par plus de 7.000.000 utilisateurs par les entreprises de toute taille petites ou grandes.",
  },
  {
    name: "Fiabilité",
    description:
      "Avec plus de 16 ans d'existante Odoo est une solution fialbe et robuste.",
  },
];
const apps = [
  {
    name: "CRM",
    description: "",
    icon: UserAddIcon,
    color: "text-sky-600",
  },
  {
    name: "Vente",
    description: "Nous integrons vos besoin avec Odoo ERP",
    icon: CashIcon,
    color: "text-indigo-600",
  },
  {
    name: "Facturation",
    description: "Nous integrons vos besoin avec Odoo ERP",
    icon: DocumentTextIcon,
    color: "text-red-600",
  },
  {
    name: "Point de vente",
    description: "Nous integrons vos besoin avec Odoo ERP",
    icon: AiOutlineShop,
    color: "text-green-600",
  },
  {
    name: "Projets et services",
    description: "Nous integrons vos besoin avec Odoo ERP",
    icon: BriefcaseIcon,
    color: "text-purple-600",
  },
  {
    name: "Production",
    description: "Nous integrons vos besoin avec Odoo ERP",
    icon: MdOutlinePrecisionManufacturing,
    color: "text-pink-600",
  },
  {
    name: "Site web",
    description: "Nous integrons vos besoin avec Odoo ERP",
    icon: GlobeIcon,
    color: "text-yellow-600",
  },
  {
    name: "E-commerce",
    description: "Nous integrons vos besoin avec Odoo ERP",
    icon: ShoppingCartIcon,
    color: "text-fuchsia-600",
  },
  {
    name: "Documents",
    description: "Nous integrons vos besoin avec Odoo ERP",
    icon: DocumentDuplicateIcon,
    color: "text-emerald-600",
  },
  {
    name: "",
    description: "",
    icon: () => <div></div>,
    color: "",
  },
  {
    name: "Votre application sur mesure",
    description: "Nous integrons vos besoin avec Odoo ERP",
    icon: StarIcon,
    color: "text-cyan-500 bg-gradient-to-br from-yellow-100 to-yellow-300",
  },
];
const OdooSection = () => (
  <section id="odoo" className="relative bg-fuchsia-100 overflow-x-clip">
    <div className="absolute top-0 left-0 -ml-56 opacity-75 w-96 h-96">
      <svg
        className="text-pink-500 opacity-50 fill-current w-88 h-88"
        viewBox="0 0 200 200"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25.5,-24.7C35.8,-15.2,48.9,-7.6,50.6,1.7C52.3,11,42.6,22,32.3,31.2C22,40.4,11,47.8,-1.9,49.8C-14.9,51.7,-29.7,48.1,-41.1,38.9C-52.5,29.7,-60.5,14.9,-60.3,0.3C-60,-14.3,-51.5,-28.6,-40,-38.1C-28.6,-47.6,-14.3,-52.3,-3.4,-48.9C7.6,-45.5,15.2,-34.2,25.5,-24.7Z"
          transform="translate(100 100)"
        ></path>
      </svg>
    </div>
    <div className="absolute top-0 right-0 -mb-56 opacity-75 w-96 h-96 -mr-72">
      <svg
        className="w-full h-full text-yellow-500 opacity-50 fill-current"
        viewBox="0 0 200 200"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M44.3,-48.3C55.3,-33.4,60.4,-16.7,62.2,1.8C64,20.3,62.5,40.6,51.6,55.3C40.6,70,20.3,79,2.3,76.7C-15.7,74.4,-31.4,60.7,-46.7,46C-62,31.4,-76.9,15.7,-78.5,-1.6C-80,-18.8,-68.2,-37.6,-52.9,-52.5C-37.6,-67.5,-18.8,-78.5,-1.1,-77.5C16.7,-76.4,33.4,-63.3,44.3,-48.3Z"
          transform="translate(100 100)"
        ></path>
      </svg>
    </div>
    <div className="mx-auto lg:container lg:pl-8">
      <div className="flex flex-col w-full h-full p-8 my-10 text-gray-900 ">
        <h2
          className="m-0 text-4xl font-extrabold leading-tight tracking-tight text-left text-black sm:text-4xl md:text-5xl"
          data-sal="slide-right"
          data-sal-delay="300"
          data-sal-easing="ease"
        >
          Pourquoi implémenter notre ERP basé sur Odoo
        </h2>
        <p
          className="mt-2 mb-12 text-xl text-left sm:text-2xl"
          data-sal="slide-right"
          data-sal-delay="300"
          data-sal-easing="ease"
        >
          Afin de gagner en productivité et réduire vos coûts de gestion. Odoo
          est la solution qu'il vous faut ! Grâce à sa modularité: il n'est plus
          question de faire coopérer différentes technologies. Odoo vous permet
          de gérer votre entreprise, d'automatiser vos processus, de réaliser
          des économies, le tout à partir d'une seule plateforme.
        </p>
        <div className="flex flex-col lg:flex-row">
          <div className="mx-0 my-10">
            {features.map((feature, i) => (
              <div
                className="p-2 rounded-lg group hover:bg-white"
                data-sal="slide-up"
                data-sal-delay={300 + i * 100}
                data-sal-easing="ease"
              >
                <dt>
                  <CheckIcon
                    className="absolute w-6 h-6 text-green-500"
                    aria-hidden="true"
                  />
                  <p className="text-xl font-bold leading-6 text-gray-900 ml-9">
                    {feature.name}
                  </p>
                </dt>
                <dd className="mt-2 text-base text-gray-700 ml-9">
                  {feature.description}
                </dd>
              </div>
            ))}
          </div>
          <div className="flex-shrink-0 lg:w-1/2">
            <div className="order-1 w-full px-3 pb-4 lg:order-0">
              <div className="relative w-full">
                {/* Animation boule qui tourne */}
                {/* <div className="max-w-lg">
                        <div className="absolute top-0 bg-purple-300 rounded-full -left-4 w-72 h-72 mix-blend-multiply filter blur-xl opacity-70 animate-blob"></div>
                        <div className="absolute top-0 bg-green-300 rounded-full left-40 w-72 h-72 mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-2000"></div>
                        <div className="absolute bg-pink-300 rounded-full -bottom-8 left-20 w-72 h-72 mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-4000"></div>
                      </div> */}
                <h3
                  className="py-4 text-3xl font-bold text-center"
                  data-sal="slide-right"
                  data-sal-delay="300"
                  data-sal-easing="ease"
                >
                  Les applications Odoo
                </h3>
                <ul
                  className="grid grid-cols-3"
                  data-sal="slide-right"
                  data-sal-delay="400"
                  data-sal-easing="ease"
                >
                  {apps.map((app) => (
                    <li className="flex flex-col items-center py-3 transition transform hover:scale-125">
                      <app.icon
                        className={classNames(
                          app.color,
                          "w-16 h-16 rounded-lg p-2 bg-white border-4 shadow-xl border-gray-200"
                        )}
                      />
                      <span className="text-lg font-medium text-center text-gray-700 sm:text-xl">
                        {app.name}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="h-0">
      <div className="px-4 mx-auto transform -translate-y-20 max-w-7xl sm:px-6 lg:px-8">
        <div className="max-w-5xl mx-auto">
          <dl className="flex mx-auto space-x-4">
            {stats.map((stat) => (
              <div className="relative flex-1 overflow-hidden transition transform bg-white rounded-lg shadow group w-60 md:w-72 hover:scale-105">
                <div className="px-4 py-5 sm:p-6">
                  <dd className="mt-1 text-xl font-semibold leading-9 text-gray-900 truncate md:text-4xl">
                    {stat.number}
                  </dd>
                  <dt className="text-sm font-medium leading-5 text-gray-700 truncate md:text-base">
                    {stat.name}
                  </dt>
                </div>
                <div
                  className={classNames(
                    stat.color,
                    "h-20 w-16 pt-8 pl-2 lg:h-28 lg:w-28 lg:p-6 rounded-full absolute -top-6 -right-6 transform transition group-hover:scale-125 lg:group-hover:-translate-x-5 lg:group-hover:translate-y-5"
                  )}
                >
                  <stat.icon className="w-6 h-6 text-white lg:h-16 lg:w-16" />
                </div>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  </section>
);
/* <li>
              Service clien: Une équipe réactive et 100 % vous accompagne au
              quotidien par chat ou email …
            </li>
            <li>
              Ecoute et conseil: Nous jouerons le rôle du conseiller technique
              du client, PSS possède les ressources nécessaires pour répondre
              aux besoins clients.
            </li>
            <li>
              Nous sommes une équipe de passionnés dont l'objectif est
              d'améliorer la vie de chacun à l'aide de produits innovants. Nous
              construisons des produits remarquables pour résoudre les problèmes
              de votre entreprise. Odoo est conçu pour les petites et moyennes
              entreprises souhaitant optimiser leurs performances.
            </li> */
export default OdooSection;
