import React from "react";
import LogoReact from "../../svg/tech/logo_react.svg";
import LogoGatsby from "../../svg/tech/logo_gatsby.svg";
import LogoGraphql from "../../svg/tech/logo_graphql.svg";
import LogoNext from "../../svg/tech/logo_next.svg";
import LogoNode from "../../svg/tech/logo_node.svg";
import LogoOdoo from "../../svg/tech/logo_odoo.svg";

const techs = [
  {
    name: "Odoo",
    icon: LogoOdoo,
  },
  {
    name: "Node.js",
    icon: LogoNode,
  },
  {
    name: "React",
    icon: LogoReact,
  },
  {
    name: "Next.js",
    icon: LogoNext,
  },
  {
    name: "Gatsby",
    icon: LogoGatsby,
  },
  {
    name: "GraphQL",
    icon: LogoGraphql,
  },
];

const TechnologieSection = () => (
  <section
    id="technologies"
    className="pt-20 bg-gradient-to-r from-green-400 to-sky-400"
  >
    <div className="flex justify-center">
      <p className="px-4 py-2 text-4xl text-white  text-center">
        Les meilleurs technologie à votre service
      </p>
    </div>
    <div className="max-w-screen-xl px-4 py-1 mx-auto sm:px-6 lg:px-8">
      <div className="max-w-screen-xl px-4 py-12 mx-auto sm:px-6 lg:px-8">
        <div className="grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-6 text-white fill-current">
          {techs.map((tech, i) => (
            <div
              className="flex items-center justify-center col-span-1 transition transform hover:scale-125 hover:-translate-y-11 max-h-28 md:col-span-2 lg:col-span-1"
              data-sal="slide-right"
              data-sal-delay={400 + i * 100}
              data-sal-easing="ease"
            >
              <tech.icon alt={tech.name} />
            </div>
          ))}
        </div>
      </div>
    </div>
  </section>
);
export default TechnologieSection;
