import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/layout/Layout";
import Top from "../components/index/top";
import ServicesSection from "../components/index/services";
import OdooSection from "../components/index/odoo";
import TechnologieSection from "../components/index/technologies";

//TODO:gradient with granim
const IndexPage = () => {
  return (
    <Layout>
      <div className="flex flex-col snap-x snap-mandatory">
        <Helmet>
          <html lang="en" />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="CodeConik est une agence de développement de solution informatique, nous nous spécialisons dans les ERP, nous avons adapté Odoo pour l'Algérie. Le développement Web basé sur Jamstack, React, nextJS, Gatsby, GraphQL."
          />
          <title>
            CodeConik: ERP basé sur Oddo pour l'Algérie, développement Web basé
            sur Jamstack
          </title>
          <link rel="canonical" href="http://codeconik.com/" />
        </Helmet>
        <Top />
        <ServicesSection />
        <OdooSection />
        <TechnologieSection />
      </div>
    </Layout>
  );
};
export default IndexPage;
